import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { useMutationSupplyPointProvider } from "@/src/ui/pages/supply_point/provider/mutation_supply_point.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { lazy, useEffect, useState } from "react";
import { useListSupplyPointProvider } from "@/src/ui/pages/supply_point/views/supply_point_page/provider/list_supply_point.provider";
import { useAutocompleteClientsProvider } from "@/src/ui/pages/supply_point/provider/autocomplete_client.provider";
import { useUserProvider } from "@/src/ui/provider/user.slice";
import { useMutationClientProvider } from "@/src/ui/pages/client/provider/mutation_client.provider";

const ListSupplyPointPage = lazy(() => import("@/src/ui/pages/supply_point/views/supply_point_page/supply_point_page"));
const CreateSupplyPointPage = lazy(() => import("@/src/ui/pages/supply_point/views/create_supply_point_page/create_supply_point_page"));
const DetailSupplyPointPage = lazy(() => import("@/src/ui/pages/supply_point/views/detail_supply_point_page/detail_supply_point_page"));
const EditSupplyPointPage = lazy(() => import("@/src/ui/pages/supply_point/views/edit_supply_point_page/edit_supply_point_page"));

const ID_PATH_PARAM = ":supplyPointId";

export default function SupplyPointPages() {
  const navigate = useNavigate();
  const userPermissions = useUserProvider((state) => state.userPermission);
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    if (userPermissions) {
      if (userPermissions && userPermissions["supply_points"] && userPermissions["supply_points"].list) {
        let list = [];
        list.push(
          {
            index: true,
            element: (
              <useListSupplyPointProvider.State>
                <ListSupplyPointPage />
              </useListSupplyPointProvider.State>
            )
          },
          {
            path: paths.supplyPoint.detail + ID_PATH_PARAM,
            element: <DetailSupplyPointPage />
          },
          { path: WILDCARD_PATH, element: <Navigate to={paths.supplyPoint.index} replace /> }
        );

        if (userPermissions["supply_points"].create) {
          list = list.concat([
            {
              path: paths.supplyPoint.create,
              element: (
                <useAutocompleteClientsProvider.State builderProps={{ filterByActiveClients: true }}>
                  <useMutationClientProvider.State>
                    <CreateSupplyPointPage />
                  </useMutationClientProvider.State>
                </useAutocompleteClientsProvider.State>
              )
            },
            {
              path: paths.supplyPoint.edit + ID_PATH_PARAM,
              element: <EditSupplyPointPage />
            }
          ]);
        }
        setRouteList(list);
      } else {
        navigate("/");
      }
    }
  }, [userPermissions, navigate]);

  const page = useRoutes(routeList);
  return (
    <AppErrorBoundary key="supply_point-error">
      <useMutationSupplyPointProvider.State>{page}</useMutationSupplyPointProvider.State>
    </AppErrorBoundary>
  );
}
