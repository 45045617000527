import { createProvider } from "@/src/common/utils/zustand";
import type { AutocompleteState } from "@/src/ui/view_models/autocomplete_state";
import { getAutocompleteInitialState } from "@/src/ui/provider/autocomplete.slice";
import ChannelController from "../pages/channel/controllers/channel_controller";

interface AutocompleteProviderBuilderProps {
  filterByActiveChannel?: boolean;
}

interface Props extends AutocompleteState {
  filterByActiveChannel?: boolean;
}

export const useAutocompleteChannelProvider = createProvider<Props, AutocompleteProviderBuilderProps>(({ filterByActiveChannel }) => {
  return (set, get) => ({
    ...getAutocompleteInitialState(get, set),
    filterByActiveChannel,
    async getByName() {
      set({ isLoading: true });
      try {
        const response = await ChannelController.getAllChannels({
          name: get().filterName
        });
        set({ items: response.items.map((c) => ({ label: c.name, id: c.id })) });
      } catch {
      } finally {
        set({ isLoading: false });
      }
    }
  });
});
