import { useNavigate, useRoutes } from "react-router-dom";
import paths from "@/src/ui/router/paths";
import { useMutationDocumentSupplyPointProvider } from "@/src/ui/pages/document/provider/mutation_document_supply_point.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { lazy, useEffect, useState } from "react";
import { useUserProvider } from "@/src/ui/provider/user.slice";

const CreateDocumentSupplyPointPage = lazy(() => import("@/src/ui/pages/document/views/create_document_page/create_document_supply_point_page"));
const EditDocumentSupplyPointPage = lazy(() => import("@/src/ui/pages/document/views/edit_document_page/edit_document_supply_point_page"));

const ID_PATH_PARAM = ":documentId";

export default function DocumentSupplyPointPages() {
  const navigate = useNavigate();
  const userPermissions = useUserProvider((state) => state.userPermission);
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    if (userPermissions) {
      const list = [];
      list.push(
        {
          path: paths.documentSupplyPoint.create,
          element: <CreateDocumentSupplyPointPage />
        },
        {
          path: paths.documentSupplyPoint.edit + ID_PATH_PARAM,
          element: <EditDocumentSupplyPointPage />
        }
      );
      setRouteList(list);
    }
  }, [userPermissions, navigate]);

  const page = useRoutes(routeList);

  return (
    <AppErrorBoundary key="document-error">
      <useMutationDocumentSupplyPointProvider.State>{page}</useMutationDocumentSupplyPointProvider.State>
    </AppErrorBoundary>
  );
}
