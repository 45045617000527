import type { FieldInputProps, FieldMetaProps, FormikHandlers } from "formik";
import { useField } from "formik";
import type { BaseFormikProps } from "../../view_models/formik";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { type FocusEventHandler, type HTMLInputTypeAttribute, type KeyboardEventHandler, type ReactNode, type WheelEventHandler } from "react";
import Styled from "./input.styled";
import { emptyString } from "@/src/common/utils";

let lastValue = "";

interface InputProps {
  label?: string;
  id: string;
  name?: string;
  onChange?: FormikHandlers["handleChange"];
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FormikHandlers["handleBlur"];
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onWheel?: WheelEventHandler<HTMLInputElement>;
  formik?: BaseFormikProps<string | undefined>;
  value?: string;
  className?: string;
  type?: HTMLInputTypeAttribute;
  decimals?: number;
  icon?: ReactNode;
  onClickIcon?: () => void;
  disabled?: boolean;
  readonly?: boolean;
  min?: number;
  required?: boolean;
  isFilter?: boolean;
  isPassword?: boolean;
}

export const Input = ({
  value,
  onChange,
  onKeyPress,
  onBlur,
  onKeyDown,
  label,
  formik,
  name,
  id,
  type = "text",
  decimals = 2,
  icon,
  onClickIcon,
  disabled = false,
  readonly = false,
  required = false,
  className,
  min,
  isFilter = false,
  isPassword = false
}: InputProps) => {
  let field: FieldInputProps<string | undefined>;
  let meta: FieldMetaProps<string | undefined>;
  // TODO forcing values to be empty strings instead of undefined to avoid uncontrolled. Inputs in form values should always be string, even numbers.
  if (formik) {
    field = formik.field;
    meta = formik.meta;
    field.value = field.value ?? "";
  } else {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const emptyHandler = () => {};
    field = {
      onBlur: onBlur || emptyHandler,
      value: value ?? "",
      onChange: onChange || emptyHandler,
      name: name || ""
    };
    meta = { initialTouched: false, initialValue: undefined, value: undefined, touched: false };
  }

  return (
    <Styled.InputWrapper>
      <FormControl variant="outlined" required={required}>
        {type === "file" ? (
          <input
            type="file"
            id={id}
            onChange={(event) => {
              const files = event.target.files;
              if (files && files.length > 0) {
                field.onChange(event);
              }
            }}
          />
        ) : (
          <>
            <InputLabel htmlFor={id}>{label}</InputLabel>

            <OutlinedInput
              label={label}
              type={type}
              id={id}
              onKeyDown={onKeyDown}
              onKeyPress={onKeyPress}
              {...field}
              className={className}
              disabled={disabled}
              readOnly={readonly}
              inputProps={{ min }}
              onChange={(e) => {
                if (type === "number") {
                  const inputValue = e.target.value;
                  let pattern;
                  if (decimals > 0) {
                    pattern = "^\\d+[,.]?\\d{0," + decimals + "}$";
                  } else {
                    pattern = "^\\d+$";
                  }
                  const regex = new RegExp(pattern);
                  const result = regex.test(inputValue);

                  if (result) {
                    lastValue = inputValue;
                    e.target.value = inputValue;
                  } else if (!result && inputValue != "" && lastValue != "") {
                    e.target.value = lastValue;
                  } else if (!result) {
                    lastValue = "";
                    e.target.value = "";
                  }

                  //e.target.value = e.target.value.replace(/\D/g, '')
                  field.onChange(e);
                } else if (type === "phone") {
                  const inputValue = e.target.value;
                  const regex = /^\d{0,9}$/;
                  const result = regex.test(inputValue);

                  if (result) {
                    e.target.value = inputValue;
                    field.onChange(e);
                  }
                } else if (isPassword) {
                  const inputValue = e.target.value;
                  if (!inputValue.includes(" ")) {
                    e.target.value = inputValue;
                    field.onChange(e);
                  }
                } else {
                  field.onChange(e);
                }
              }}
              endAdornment={
                icon ? (
                  <InputAdornment position="end">
                    <IconButton onClick={onClickIcon} edge="end">
                      {icon}
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
              onFocus={(e) => {
                e.target.addEventListener("wheel", (ev) => {
                  ev.preventDefault();
                });
              }}
              sx={{
                height: isFilter ? 35 : 55
              }}
            />
          </>
        )}

        {meta.error && meta.touched && (
          <Styled.Error>
            <p dangerouslySetInnerHTML={{ __html: meta.error }} />
          </Styled.Error>
        )}
      </FormControl>
    </Styled.InputWrapper>
  );
};

type InputFormikProps = Omit<InputProps, "formik" | "name" | "id"> & { name: string; id?: string };

export const InputFormik = ({
  id,
  label,
  name,
  onChange,
  className = emptyString,
  type,
  decimals,
  icon,
  onClickIcon,
  disabled = false,
  required = false,
  readonly,
  min,
  isFilter,
  isPassword
}: InputFormikProps) => {
  const [field, meta] = useField({ name, type });
  if (onChange) field.onChange = onChange;
  return (
    <Input
      id={id ?? name}
      formik={{ field, meta }}
      label={label}
      className={className}
      icon={icon}
      type={type}
      decimals={decimals}
      onClickIcon={onClickIcon}
      disabled={disabled}
      readonly={readonly}
      required={required}
      min={min}
      isFilter={isFilter}
      isPassword={isPassword}
    />
  );
};
