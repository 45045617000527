import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BlackLettersLogo, Home } from "../../assets/icons";
import paths from "@/src/ui/router/paths";
import IconWithTooltip from "../icon_with_tooltip/icon_with_tooltip";
import UserSettings from "../user_settings/user_settings";
import Styled from "./header.styled";
import Autocomplete from "../autocomplete/autocomplete";
import { useAutocompleteChannelProvider } from "../../provider/autocomplete_channel.provider";
import { useEffect } from "react";
import { useUserProvider } from "../../provider/user.slice";

export default function Header() {
  const { t } = useTranslation("common");
  const user = useUserProvider((state) => state.user);
  const permissions = useUserProvider((state) => state.permissions);
  const userPermissions = useUserProvider((state) => state.userPermission);
  const selectChannel = useUserProvider((state) => state.selectChannel);

  const { channels, getByName, setChannel } = useAutocompleteChannelProvider((state) => ({
    channels: state.items,
    getByName: state.getByName,
    setChannel: state.setFilterName
  }));

  useEffect(() => {
    async function prepare() {
      if (!userPermissions) {
        await permissions();
      } else {
        if (userPermissions["channels"] && userPermissions["channels"]["list"]) {
          getByName();
        }
      }
    }

    prepare();
  }, [permissions, userPermissions, getByName]);

  return (
    <Styled.Header>
      <Styled.HeaderContent>
        <Styled.HomeContent>
          <Link to={paths.home.index}>
            <BlackLettersLogo />
          </Link>
          <Link to={paths.home.index}>
            <IconWithTooltip tooltip={t("header.home")}>
              <Home />
            </IconWithTooltip>
          </Link>
        </Styled.HomeContent>

        <Styled.UserContent>
          {user && !user.channel && (
            <div style={{ marginLeft: 10, width: 180 }}>
              <Autocomplete
                options={channels}
                label="Canal"
                size={"small"}
                onInputChange={setChannel}
                onChange={(value) => {
                  if (value !== null && "id" in value) {
                    selectChannel(value?.id);
                  }
                }}
              />
            </div>
          )}
          {/* <Styled.IconsWrapper>
            <IconWithTooltip tooltip={t("header.notifications")}>
              <Notifications />
            </IconWithTooltip>
            <IconWithTooltip tooltip={t("header.settings")}>
              <Settings />
            </IconWithTooltip>
          </Styled.IconsWrapper> */}
          <UserSettings />
        </Styled.UserContent>
      </Styled.HeaderContent>
    </Styled.Header>
  );
}
