import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { useMutationClientProvider } from "@/src/ui/pages/client/provider/mutation_client.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { lazy, useEffect, useState } from "react";
import { useListClientProvider } from "@/src/ui/pages/client/views/client_page/provider/list_client.provider";
import { useUserProvider } from "@/src/ui/provider/user.slice";
import { useAutocompleteMarketersProvider } from "../../rate/provider/autocomplete_marketer.provider";

const ListClientPage = lazy(() => import("@/src/ui/pages/client/views/client_page/client_page"));
const CreateClientPage = lazy(() => import("@/src/ui/pages/client/views/create_client_page/create_client_page"));
const DetailClientPage = lazy(() => import("@/src/ui/pages/client/views/detail_client_page/detail_client_page"));
const EditClientPage = lazy(() => import("@/src/ui/pages/client/views/edit_client_page/edit_client_page"));

const ID_PATH_PARAM = ":clientId";

export default function ClientPages() {
  const navigate = useNavigate();
  const userPermissions = useUserProvider((state) => state.userPermission);
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    if (userPermissions) {
      if (userPermissions && userPermissions["clients"] && userPermissions["clients"].list) {
        let list = [];
        list.push(
          {
            index: true,
            element: (
              <useListClientProvider.State>
                <ListClientPage />
              </useListClientProvider.State>
            )
          },
          {
            path: paths.client.detail + ID_PATH_PARAM,
            element: <DetailClientPage />
          },
          { path: WILDCARD_PATH, element: <Navigate to={paths.client.index} replace /> }
        );

        if (userPermissions["clients"].create) {
          list = list.concat([
            {
              path: paths.client.create,
              element: (
                <useAutocompleteMarketersProvider.State>
                  <CreateClientPage />
                </useAutocompleteMarketersProvider.State>
              )
            },
            {
              path: paths.client.edit + ID_PATH_PARAM,
              element: (
                <useAutocompleteMarketersProvider.State>
                  <EditClientPage />
                </useAutocompleteMarketersProvider.State>
              )
            }
          ]);
        }

        setRouteList(list);
      } else {
        navigate("/");
      }
    }
  }, [userPermissions, navigate]);

  const page = useRoutes(routeList);

  return (
    <AppErrorBoundary key="client-error">
      <useMutationClientProvider.State>{page}</useMutationClientProvider.State>
    </AppErrorBoundary>
  );
}
